import { instance } from '@/utils/request'

// 人员账号列表数据接口
export function getSysUserList (data){
  return instance({
    method: 'post',
    url: '/v1/sysUser/getSysUserList',
    data,
  })
}

// 人员账号新增数据接口
export function  saveOrUpdateSysUser(data){
  return instance({
    method: 'post',
    url: '/v1/sysUser/saveOrUpdateSysUser',
    data,
  })
}

// 角色列表
export function getSysRoleList(){
  return instance({
    method: 'post',
    url: '/v1/sysRole/getSysRoleList',
    
  })
}
// 新增角色
export function saveSysRole(data){
  return instance({
    method: 'post',
    url: '/v1/sysRole/saveSysRole',
    data
  })
}


// 获取角色全量资源树
export function getPermissionCode1(id){
  return instance({
    method: 'post',
    url: `/v1/sysResource/getRoleResourceTree/${id}`,
  })
}
// 根据id切换
export function getRoleResourceTree(id){
  return instance({
    method: 'post',
    url: `/v1/sysResource/getRoleResourceTree/${id}`,
  })
}


// 编辑角色
export function saveOrUpdatePermission(data){
  return instance({
    method: 'post',
    url: '/v1/sysPermission/saveOrUpdatePermission',
    data
  })
}
// 删除角色
export function deleteSysRole(id){
  return instance({
    method: 'post',
    url: `/v1/sysRole/deleteSysRole/${id}`,
    
  })
}

// 删除人员账号
export function deleteSysUser(data){
  return instance({
    method: 'post',
    url: '/v1/sysUser/deleteSysUser',
    data
    
  })
}

// 发薪企业列表
export function getSalaEntPageList(data){
  return instance({
    method: 'post',
    url: '/v1/sysEnt/getSalaEntList',
    data
    
  })
}
// 发薪企业列表
export function getSalaEntList(){
  return instance({
    method: 'post',
    url: '/v1/sysEnt/payConfigEnterprises',
  })
}


// 分配发薪企业
export function disPayEnt(data){
  return instance({
    method: 'post',
    url: '/v1/sysUser/disPayEnt',
    data
    
  })
}


// 关联发薪企业列表
export function relatedSalaryPayingEnterprises(){
  return instance({
    method: 'post',
    url: '/v1/sysEnt/payConfigEnterprises',
  })
}
// 新增或编辑用工企业
export function saveOrUpdateCustomSysEnt( data ){
  return instance({
    method: 'post',
    url: '/v1/sysEnt/saveOrUpdateCustomSysEnt',
    data,
  })
}
// // 上传
// export function saveOrUpdateCustomSysEnt( data ){
//   return instance({
//     method: 'post',
//     url: '/v1/sysEnt/saveOrUpdateCustomSysEnt',
//     data,
//   })
// }