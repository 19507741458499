<template>
  <div class="character_content">
    <div class="character_bgm">
      <div class="tinymceSchedule_title">
        <img src="../../../assets/bgm/08.png" alt="" />
        <p>角色权限</p>
      </div>
      <!-- 角色名称 -->
      <div class="character_main">
        <div class="part-left">
          <div class="left-title"   v-if=" $store.state['user']['PermissionCodeList'].includes('insertRolePermission' )">
            <span>角色名称</span>
            <a @click="handleToAdd" style="">
              <i class="el-icon-circle-plus"></i>
              新增角色
            </a>
          </div>
          <div
            class="left-row"
            v-for="(item, index) in characterList"
            :key="index"
            :ref="'left_row' + index"
            @click="handleShowEdit(index, item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>

        <div class="part-right">
          <div class="right-top">
            <div class="upd-part">
              <span>角色名称：</span>
              <el-input size="medium" v-model="characterForm.name"></el-input>
            </div>

            <div class="btn-part">
              <el-button
                :disabled="characterList.length <= 0"
                type="primary"
                @click="handleSubmit('upd')"
                icon="el-icon-edit"
                style="margin-right:10px"
                size="medium"
                v-if=" $store.state['user']['PermissionCodeList'].includes('updateRolePermission' )"
                >保存修改</el-button>
                 <el-button
                  type="danger"
                  :disabled="characterList.length <= 0"
                  icon="el-icon-delete"
                  @click="deletedData"
                  size="medium"
                  v-if=" $store.state['user']['PermissionCodeList'].includes('deleteRolePermission' )"
                  >删除角色</el-button>             
            </div>
          </div>

          <div class="right-table">
            <div>
              <span>模块</span>
              <span>可选项</span>
            </div>

            <div
              class="table-row"
              v-for="(item) in resourceIdData"
              :key="item.id" >
              <span>{{ item.name }}</span>
              <span>
                <!-- <h1>测试测试----{{item.code}}--------{{testData[item.code]}}</h1> -->
                <!-- <el-checkbox-group v-model="characterForm.resourceIdList" > -->
                <el-checkbox-group v-model="testData[item.code]">
                  <el-checkbox @change="change(opt.id)" v-for="opt in item.childResourceList" :key="opt.id" :label="opt.id">{{ opt.name }}</el-checkbox>
                </el-checkbox-group>
              </span>
            </div>
          </div>
        </div>
      </div>
        <!-- 新增弹框 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="dialogCloseAudit">
        <template slot="title">
          <div class="title">
            <p></p>
            <p>新增</p>
          </div>
        </template>
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" style="margin-left:40px">
          <el-form-item label="角色:" prop="roleName">
            <el-input size="medium" v-model="form.roleName" placeholder="请输入角色" style="width:70%"></el-input>
          </el-form-item>
        </el-form>
        <div class="line"></div>
        <el-button class="button" size="medium"  type="primary" @click="newlyData" :loading="loading">新增</el-button>
        <el-button size="medium" @click="dialogCloseAudit">取 消</el-button>
        
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getSysRoleList,saveSysRole,getRoleResourceTree,saveOrUpdatePermission,deleteSysRole } from '@/api/lssuingModule'
export default {
  data() {
    return {
      checkList:[],
      characterList: [],
      resourceIdData: [],
      childResourceList: [],
      resourceIdList: [],
      testData: {},
      characterForm: {
        resourceIdList: [],
        name: '',
        id: ''
      },
      dialogVisible:false,
      loading:false,
      index: 0,
      roleNameId:'',
      form:{
        roleName:'',
      },
       rules:{
        roleName:[
           { required: true,message: '请输入您的角色', trigger: ['blur', 'change'] },
        ],
      }

    }
  },
  created() {
    this.getRoleList()
  },
  watch: {
    characterList: function (nval, oval) {
      if (nval.length <= 0) {
        this.characterForm = {
          resourceIdList: [],
          name: '',
          id: ''
        }
      }
    }
  },
  methods: {
    // 角色列表
    async getRoleList(text) {
      const res = await getSysRoleList()
      if (res.success) {
        this.characterList = res?.data
         if (text !== 'upd' && text !== 'add' && this.characterList.length > 0) {
            this.handleShowEdit(this.index, this.characterList[this.index])
          } else if (text === 'add') {
            this.index = this.characterList.length - 1
            this.handleShowEdit(this.index, this.characterList[this.index])
          }
      }
    },
    handleToAdd() {
       this.dialogVisible = true
       this.form.roleName = ''
        this.$nextTick(() => {
        this.$refs.form.resetFields();
      })

    },
    //保存修改
    handleSubmit(text) {
      // console.log('testData------------2',this.testData);
      let strArr = []
      for (const key in this.testData) {
        // console.log('key-0-------',key);
        // console.log('this.testData[key]',this.testData[key]);
          if(this.testData[key].length > 0){
            this.testData[key].forEach(item=>{
            strArr.push(item)
            })
          }
      }
      // console.log('strArr-----',strArr);
       let params = {}
       params = { 
         name:this.characterForm.name,
         id:this.characterForm. id,
         resourceIds:strArr
        }
        // 后面回显得逻辑
        // 例如
        // arr.forEach(item=>{
          
        // })
       saveOrUpdatePermission(params).then(res => {
         if (res.success) {
            this.getRoleList()
            this.$message.success('提交成功')
            this.checkList = []
         } else {
          //  this.$message.error(res.message)
         }
       })
    },
    //删除
    deletedData () {
        this.$confirm('确定要删除嘛?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          const res = await deleteSysRole(this.characterList[this.index].id)
          if (res.success) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.index = 0
                this.getRoleList()
             } else {
              //  this.$message.error(res.message)
             }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    change (id) {
      // console.log('yunxingl');
      this.checkList.push(id)
    },
  
    // 点击切换角色
    handleShowEdit(index, row) {
      this.index = index
      this.$nextTick( () => {
        let num = 0
        for (const k in this.$refs) {
           if (k.includes('left_row') && this.$refs[k]?.length > 0) {
               num++
          }
        }
        for (let i = 0; i < num; i++) {
           this.$refs['left_row' + i][0].style.color = '#000'
           this.$refs['left_row' + i][0].style.background = '#fff'
        }
        this.$refs['left_row' + index][0].style.color = '#4070f9'
        this.$refs['left_row' + index][0].style.background = 'rgb(247,249,254)'
      })
      const {  name, id,  } = row
      getRoleResourceTree (row.id).then( res => {
         if ( res.success ) {
           for (let i = 0; i < res?.data.length; i++) {
             this.$set(this.testData,res?.data[i].code,[])
            //  debugger
            for (let t = 0; t < res?.data[i].childResourceList.length; t++) {
              if(res?.data[i].childResourceList[t].selected){
                // console.log('接口获取选中得选项',res?.data[i].childResourceList[t].id);
                this.testData[res?.data[i].code].push(res?.data[i].childResourceList[t].id)
              }
            }
             
           }
    
            // console.log('testDara---------1',this.testData)
            this.resourceIdData = res?.data
            // console.log( this.resourceIdData,' this.resourceIdData+++');
            
            this.characterForm = { name, id, resourceIdList: res.data}
         } else {
            // this.$notification.error('获取角色权限数据失败。' + res.message)
          }
      }).catch((error) => {
          // this.$notification.error(error)
      })
    },
    // 新增角色
    newlyData () {
      this.$refs.form.validate( async valid => {
        if (valid) {
          this.loading = true
          const res = await saveSysRole({
            name:this.form.roleName,
          })
          if ( res.success ) {
             this.loading = false
             this.$message.success('新增成功')
             this.getRoleList()
             this.dialogVisible = false

          } else {
            //  this.$message.error(res.message)
             this.loading = false
          }
        }
      })
    },
    dialogCloseAudit () {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.character_content {
  padding: 20px;
  .character_bgm {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    .tinymceSchedule_title {
      width: 100%;
      border-bottom: 1px solid #ececec;
      display: flex;
      height: 40px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        // line-height: 22px;
      }
      p:first-child {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-left: 10px;
      }
    }
    .character_main {
      display: flex;
      .part-left {
        width: 25%;
        margin-top: 20px;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        & > div {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #000;
          font-size: 14px;
        }
        .left-title {
          background-color: #efefef;
          & > a {
            width: 128px;
            height: 36px;
            background: #6591f1;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
          }
        }
        .left-row {
          padding-left: 90px;
          justify-content: flex-start;
          border-bottom: 1px solid #e5e5e5;
          cursor: pointer;
          // &:hover {
          //   color: #4070f9;
          // }
        }

        .isSelected {
          color: #4070f9;
        }
      }
      .part-right {
        padding-left: 30px;
        padding-right: 50px;
        margin-top: 20px;
        width: 100%;
        flex: 1;
        .right-top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          & > div.upd-part {
            display: flex;
            align-items: center;
            span {
              width: 100px;
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
            }
          }
        }

        .right-table {
          border: 1px solid #e5e5e5;
          margin-top: 50px;
          & > div {
            display: flex;
            align-items: center;
            color: #000;
            border-bottom: 1px solid #e5e5e5;
            & > span:first-child {
              line-height: 60px;
              width: 120px;
              height: 60px;
              text-align: center;
              border-right: 1px solid #e5e5e5;
            }

            & > span:nth-child(2) {
              flex: 1;
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}
.button {
    background-color: #6591F1;
  }
 .title {
    display: flex;
    border-bottom:1px solid  #ECECEC;
    height: 40px;
    p:first-child {
      width: 3px;
      height: 13px;
      background: #6591F1;
      margin-top: 5px;
    }
    p:last-child {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 10px;
    }
  }
  /deep/.el-input__inner {
    background-color: #F7F8FA ;
    color: #999999;
    border: none;
  }
  .line {
    border-bottom: 1px solid #ECECEC;
    margin: 20px 0px;
  }
</style>
